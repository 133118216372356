import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import movuluLogo from './assets/movulologo.png'; // Make sure the path is correct
import "./App.css";

class App extends React.Component {
    render = () => {
        return (
            <div className="card">
                <div className="header">
                    <div className="logo">
                        <a href=".">
                            <img src={movuluLogo} alt="Movulu Logo" style={{ maxWidth: '100%', height: '40px' }} />
                        </a>
                    </div>
                    <div className="social">
                        <a href="https://twitter.com/movulohq" title="Twitter" target="_blank" rel="noopener noreferrer">
                            <IconTwitter className="icon" />
                        </a>
                    </div>
                </div>
                <div className="content">
                    <div className="title-holder">
                        <h1>Mobile Security / Segurança Móvel</h1>
                        <p>Arriving Soon / Chegando Em Breve - Addressing mobile theft in Brazil / Contra roubos de celular no Brasil</p>
                    </div>
                    <div translate="no" className='mailmunch-forms-widget-1134088'></div>
                </div>
                <div className="footer">
                    <span>Visit us |  <a className="underlined" href="https://www.reddit.com/r/movulo" target="_blank" rel="noopener noreferrer">Movulo Subreddit</a></span>
                </div>
            </div>
        );
    }
}

export default App;