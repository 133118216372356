// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
}

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: inherit;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: \"IBM Plex Sans\", sans-serif;\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\na {\n  text-decoration: none;\n}\n\na,\na:visited {\n  color: inherit;\n}\n\n#root {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
